import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { SubmissionSchema, SubmissionSchemaV2 } from '../../iag/types';

export const registrationSchema = Yup.object().shape({
  email: Yup.string().required('Please enter your email'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string().oneOf([Yup.ref('password'), null], 'Password did not match'),
});

export const sendEmailVerificationSchema = Yup.object().shape({
  email: Yup.string().required('Please enter your email'),
});

export const iagRegistrationSchema = Yup.object({
  email: Yup.string().required('Please enter your email'),
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
});

export const iagLoginSchema = Yup.object({
  email: Yup.string().required('Please enter your email'),
});

export const iagRecordInitSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Please enter record email'),
});

export const submissionSchema = Yup.object({
  contactDetails: Yup.object({
    firstName: Yup.string().required('First name is required'),
    lastName: Yup.string().required('Last name is required'),
    company: Yup.string().optional(),
    position: Yup.string().optional(),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone: Yup.string().optional(),
    relationship: Yup.string()
      .oneOf(['INDIVIDUAL', 'PARTNER', 'DIRECTOR', 'TRUSTEE', 'AGENT'], 'Relationship is required')
      .nullable()
      .required('Relationship is required'),
  }),
  memberType: Yup.string()
    .nullable()
    .oneOf(['RETAIL_INVESTOR', 'INSTITUTIONAL_INVESTOR', 'RETAIL_INVESTOR_AGENT'], 'Field is required')
    .required('Field is required'),
  legalEntity: Yup.string().when('memberType', {
    is: (val: string) => {
      return val === 'RETAIL_INVESTOR';
    },
    then: schema => {
      return schema.required('Field is required');
    },
    otherwise: schema => {
      return schema.optional();
    },
  }),
  beneficiary: Yup.string().optional(),
  abnAcn: Yup.string().optional(),
  tradingAccount: Yup.string().when('memberType', {
    is: (val: string) => {
      return val === 'RETAIL_INVESTOR';
    },
    then: schema => {
      return schema.required('Field is required');
    },
    otherwise: schema => {
      return schema.optional();
    },
  }),
  address: Yup.object({
    postCode: Yup.string().optional(),
    country: Yup.string().optional(),
    state: Yup.string().optional(),
    suburban: Yup.string().optional(),
    address: Yup.string().optional(),
  }),
  openingBalance: Yup.string().when('memberType', {
    is: 'RETAIL_INVESTOR',
    then: schema => schema.required('Field is required'),
    otherwise: schema => schema.optional(),
  }),
});

type FormValues = Yup.InferType<typeof submissionSchema>;

export const useSubmissionForm = (intitialValues: SubmissionSchemaV2) => {
  return useForm<FormValues>({
    initialValues: {
      contactDetails: {
        firstName: intitialValues.contactDetails.firstName,
        lastName: intitialValues.contactDetails.lastName,
        company: intitialValues.contactDetails.company,
        position: intitialValues.contactDetails.position,
        email: intitialValues.contactDetails.email,
        phone: intitialValues.contactDetails.phone,
        relationship: intitialValues.contactDetails.relationship,
      },
      memberType: intitialValues.memberType,
      legalEntity: intitialValues.legalEntity,
      beneficiary: intitialValues.beneficiary,
      abnAcn: intitialValues.abnAcn,
      tradingAccount: intitialValues.tradingAccount,
      address: {
        postCode: intitialValues.address.postCode,
        country: intitialValues.address.country,
        state: intitialValues.address.state,
        suburban: intitialValues.address.suburban,
        address: intitialValues.address.address,
      },
      openingBalance: intitialValues.openingBalance ?? '',
    },
    validate: yupResolver(submissionSchema),
  });
};

export const tradeDetailSchema = Yup.object({
  transactionType: Yup.string()
    .oneOf(['BUY', 'SELL', 'TRANSFER_IN', 'TRANSFER_OUT'], 'Transaction type required')
    .required('Transaction type required'),
  tradeDate: Yup.string().required('Field is required'),
  quantity: Yup.string().required('Field is required'),
  sharePrice: Yup.string().required('Field is required'),
  amountPaid: Yup.string().required('Field is required'),
  subType: Yup.string().optional(),
});

type TradeForm = Yup.InferType<typeof tradeDetailSchema>;
export const useTradeDetailForm = () => {
  return useForm<TradeForm>({
    initialValues: {
      transactionType: '',
      tradeDate: '',
      quantity: '',
      sharePrice: '',
      amountPaid: '',
      subType: '',
    },
    validate: yupResolver(tradeDetailSchema),
  });
};

export const optOutSchema = Yup.object({
  fullName: Yup.string().required('Field is required'),
  memberType: Yup.string()
    .oneOf(
      ['GROUP_MEMBER', 'DIRECTOR', 'EXECUTOR_ESTATE', 'POWER_OF_ATTORNEY', 'SOLICITOR_ACTING'],
      'Field is required.',
    )
    .required('Field is required'),
  legalEntity: Yup.string().optional(),
  email: Yup.string().email('Invalid email').required('Field is required'),
  address: Yup.object({
    postCode: Yup.string().required('Field is required'),
    country: Yup.string().required('Field is required'),
    state: Yup.string().required('Field is required'),
    suburban: Yup.string().required('Field is required'),
    address: Yup.string().required('Field is required'),
  }),
});

export type OptOutForm = Yup.InferType<typeof optOutSchema>;
export const useOptOutForm = () => {
  return useForm<OptOutForm>({
    initialValues: {
      fullName: '',
      memberType: '',
      email: '',
      legalEntity: '',
      address: {
        address: '',
        country: '',
        postCode: '',
        state: '',
        suburban: '',
      },
    },
    validate: yupResolver(optOutSchema),
    clearInputErrorOnChange: true,
  });
};
