import { Button, Group, Modal, TextInput } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useDisclosure } from '@mantine/hooks';
import * as Yup from 'yup';
import { useInitRecord } from '../hooks/useInitRecord';
import { iagRecordInitSchema } from '../../pages/register/schema';

export const CreateRecord = () => {
  const [isOpen, { close, open }] = useDisclosure(false);
  const { mutate } = useInitRecord();
  const { getInputProps, onSubmit } = useForm({
    initialValues: {
      email: '',
    },
    validate: yupResolver(iagRecordInitSchema),
  });

  const handleOnSubmit = (data: Yup.InferType<typeof iagRecordInitSchema>) => {
    mutate({
      email: data.email,
    });
  };
  return (
    <>
      <Button variant="filled" color="green" onClick={open}>
        Create Record
      </Button>
      <Modal closeOnClickOutside={false} title="Create New Record" opened={isOpen} closeOnEscape onClose={close}>
        <form
          style={{ display: 'flex', flexDirection: 'column', gap: '0.5rem' }}
          onSubmit={onSubmit(value => handleOnSubmit(value))}
        >
          <TextInput type="email" {...getInputProps('email')} label="Email" />
          <Group align="center" position="center">
            <Button onClick={close} variant="outline" color="gray">
              Cancel
            </Button>
            <Button type="submit">Confirm</Button>
          </Group>
        </form>
      </Modal>
    </>
  );
};
