import { AxiosResponse } from 'axios';
import { useMutation } from 'react-query';
import { IErrorObject } from '../../services/types';
import iagService from '../service/iag.service';
import { IagInitRecord } from '../../pages/register/types';

export const useInitRecord = () => {
  return useMutation<AxiosResponse<{ submission: string }>, IErrorObject, IagInitRecord, any>(
    async (payload: IagInitRecord) => {
      return await iagService.InitRecord(payload);
    },
    {
      onSuccess: data => {
        const response = data.data;
        const submissionId = response.submission;
        if (submissionId) {
          window.location.href = `/app/submission/${submissionId}/view`;
          return;
        }
      },
      onError(error) {},
    },
  );
};
